import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import mouseWheel from "../images/mouse-wheel.gif"
import darlene from "../images/darlene.jpg"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    // console.log("post.frontmatter.banner", post.frontmatter.banner)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.excerpt}
        />
        <section id="blog-post">
          <div
            className="container-fluid hero-section"
            style={{ height: "auto" }}
          >
            <div className="row">
              <div className="col-md-8 mx-auto vertical-align ">
                <div className="brandstamp text-left">
                  <h1>{post.frontmatter.title}</h1>
                  {post.frontmatter.excerpt && (
                    <p>{post.frontmatter.excerpt}</p>
                  )}

                  <div className="d-inline">
                    <div className="d-inline">
                      <img
                        src={darlene}
                        alt=""
                        width="50"
                        class="smNameall-author-image rounded-circle"
                      />
                    </div>
                    <div className="border-gray" />
                    <div className="d-inline">Darlene Franklin</div>
                    <div className="border-gray" />

                    <div className="d-inline">
                      <p className="pb-3 pt-3 d-inline">
                        {post.frontmatter.publishedDate}
                      </p>
                    </div>
                    <div className="border-gray" />
                    <div className="d-inline">Finance Facts</div>
                  </div>

                  {/* <div className="button-div">
                    <Link
                      to="/contact"
                      className="btn-global mr-3"
                      type="button"
                    >
                      Contact Us
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-12 text-center scroll-down">
                {/* <img
                  className="mouse-wheel"
                  src={mouseWheel}
                  alt="Scroll Down"
                /> */}
                <Link to="#blog-page-wrap-scroll" className="mouse-wheel-box">
                  <img
                    className="mouse-wheel"
                    src={mouseWheel}
                    alt="Scroll Down"
                  />
                </Link>
              </div>
            </div>

            {/*<div>
            <div className="row">
              <div className="col-md-12 vertical-align">
                <div className="brandstamp-mobile text-white">
                  <h1>{post.frontmatter.title}</h1>
                  <p className="mb-5">{post.frontmatter.publishedDate}</p>
                  <div className="button-div">
                  <Link
                    to="/contact"
                    className="btn-global mr-3"
                    type="button"
                  >
                    Contact Us
                  </Link>
                </div>
                </div>
              </div>
            </div>
          </div>*/}
          </div>

          <div id="blog-page-wrap-scroll" className="px-5 mt-5 pt-5">
            <div className="row">
              <div
                className="col-md-12 hero-inner-img vertical-align"
                style={{
                  background: `url(/${
                    post.frontmatter.banner !== null
                      ? post.frontmatter.banner
                      : "https://source.unsplash.com/user/joshhild/1366x768"
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-8 py-5 mx-auto blog-inner-img">
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <hr />

                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link
                        className="text-blue"
                        to={previous.fields.slug}
                        rel="prev"
                      >
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link
                        className="text-blue"
                        to={next.fields.slug}
                        rel="next"
                      >
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
                <div className="col pt-4">
                  <Link className="link-btn" to="/blog">
                    Take Me Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        publishedDate(formatString: "MMMM DD, YYYY")
        banner
        excerpt
      }
    }
  }
`
